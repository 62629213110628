import React, { useEffect, useState } from 'react'
import { Card, CardBody, CardTitle, CardText } from 'reactstrap'
import CountUp from 'react-countup';
import Typed from 'react-typed';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronLeft } from '@fortawesome/free-solid-svg-icons'
import BootstrapTable from 'react-bootstrap-table-next'
import { Link } from "gatsby"
import moment from 'moment'
import { randomMessage, typedDelay } from '../../helpers/utils'

const CallAttempts = ({ campaignData, isLoading, campaignStatData, campaignStatTable }) => {
  const [loadingMessage, setLoadingMessage] = useState(null)
  useEffect(() => {
    setLoadingMessage(randomMessage())
  }, [])

  const columns = [
    {
      dataField: 'group',
      text: 'Call group',
      sort: true,
    },
    {
      dataField: 'outcome',
      text: 'Call outcome',
      sort: true,
    },
    {
      dataField: 'calls',
      text: 'Calls',
      sort: true,
    },
    {
      dataField: 'percentage',
      text: '%',
      sort: true,
    },
  ]
  if (isLoading) {
    return (
      <Card className="cardHeight">
        <CardBody className="contactBody cardHeight">
          <CardTitle tag="h5" className="cardCount cardLight">
            {loadingMessage}
          </CardTitle>
          <Typed
            id='noExportOrders'
            strings={[`collecting calls${typedDelay}.${typedDelay}.${typedDelay}.${typedDelay}.${typedDelay}.`]}
            className="cardSubText cardLight"
            showCursor={false}
            typeSpeed={20}
            loop
          />
        </CardBody>
      </Card>
    )
  } else {
    return (
      <>
        {campaignData ? (
          <Card>
            <CardBody>
              <>
                <div className="d-flex flex-row activeContact">
                  <Link to={`/campaigns?id=${campaignData?.id}`}>
                    <div className="campaignStatsLink">
                      <FontAwesomeIcon icon={faChevronLeft} className="fs--2" />
                    </div>
                  </Link>
                  <div className="camiagn_Text">
                    <h3>{campaignData?.Campaign_Name}</h3>
                    <p>{campaignData?.Status}</p>
                  </div>
                </div>
                <div className="d-flex flex-row">
                  <div className="todayDate">
                    {campaignData?.Status === 'Active' ? (
                      <p>
                        {moment(campaignData?.Start_Date).format('DD/MM/YYYY')} <span> - {moment().format('DD/MM/YYYY')}</span>
                      </p>
                    ) : (
                      <p>
                        {moment(campaignData?.Start_Date).format('DD/MM/YYYY')} - {''}
                        <span>{moment(campaignData?.End_Date).format('DD/MM/YYYY')}</span>
                      </p>
                    )}
                  </div>
                </div>
                <div className="text-black text-center">
                  <h1 className="headerContact">Call attempts</h1>
                  {campaignStatData ?
                    <div className="d-flex callAttempts">
                      <div className="">
                        <CountUp
                          end={campaignStatData.info.contacts ?? 0}
                          duration={2}
                          format='alphanumeric'
                        />
                        <p>Total contacts</p>
                      </div>
                      <div className="">
                        <CountUp
                          end={campaignStatData.info.attempted ?? 0}
                          duration={2}
                          format='alphanumeric'
                        />
                        <p>Call Attempts</p>
                      </div>
                      <div className="">
                        <CountUp
                          end={campaignStatData.info.connected ?? 0}
                          duration={2}
                          format='alphanumeric'
                        />
                        <p>Connected Call</p>
                      </div>
                    </div>
                    : null}
                </div>
              </>
            </CardBody>
            {campaignStatTable && campaignStatTable.length > 0 ?
              <BootstrapTable
                bootstrap4
                keyField="outcome"
                data={campaignStatTable}
                columns={columns}
                defaultSorted={[
                  {
                    dataField: 'group',
                    order: 'asc',
                  },
                ]}
                defaultSortDirection="asc"
                headerClasses="bs-table-header SingleContacttable"
                rowClasses="bs-table-row SingleContacttable"
              />
              : <BootstrapTable
                bootstrap4
                keyField="outcome"
                data={[]}
                columns={columns}
                defaultSorted={[
                  {
                    dataField: 'Full_Name',
                    order: 'asc',
                  },
                ]}
                noDataIndication="Table is Empty"
                defaultSortDirection="asc"
                headerClasses="bs-table-header SingleContacttable"
                rowClasses="bs-table-row SingleContacttable"
              />}
          </Card>
        ) : (
          <>
            <Card className="zeroHeight">
              <Link to={`/campaign`}>
                <div className="campaignStatsLink zeroCount">
                  <FontAwesomeIcon icon={faChevronLeft} className="fs--2" />
                </div>
              </Link>
              <CardBody className="contactBody">
                <CardTitle tag="h5" className="cardCount cardLight">
                  Oops,
                </CardTitle>
                <CardText tag="p" className="cardSubText cardLight" id="noExportContacts">
                  this campaign doesn't exist
                </CardText>
              </CardBody>
            </Card>
          </>
        )}
      </>
    )
  }
}

export default CallAttempts
