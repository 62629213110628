import React, { useState, useEffect } from 'react'
import { Helmet } from "react-helmet"
import { navigate } from 'gatsby'
import { CheckToken, requestGetWithKey } from '../../../helpers/utils'
import DashboardLayout from '../../../layouts/dashboardlayout'
import CallAttempts from '../../../components/campaigns/callattempts'

const Calls = ({ params }) => {
  const [campaignData, setcampaignData] = useState(null)
  const [campaignStatData, setCampaignStatData] = useState(null)
  const [campaignStatTable, setCampaignStatTable] = useState(null)
  const [isLoading, setIsLoading] = useState(true)
  useEffect(() => {
    if (!CheckToken()) {
      navigate('/404')
    }

    const getSingleCampaign = [`api/zoho/campaigns/${params.campaignid}`]
    requestGetWithKey(getSingleCampaign).then(result => {
      if (result.message === '') {
        setcampaignData(result.message)
      } else {
        setcampaignData(result.message.data[0])
      }
    })
      .catch(error => { })
    const getSingleCallStats = [`api/zoho/campaigns/${params.campaignid}/calls/stats`]
    requestGetWithKey(getSingleCallStats).then(result => {
      setCampaignStatData(result.message)
      setCampaignStatTable(result.message.data.map(d => {
        return { group: d.group, outcome: d.outcome, calls: d.count, percentage: d.percentage.toFixed(2) }
      }))
      setIsLoading(false)
    })
      .catch(error => { setIsLoading(false) })
  }, [])
  return (
  <>
    <Helmet>
        <meta charSet="utf-8" />
        <title>Winery Portal - Calls</title>
      </Helmet>
      <DashboardLayout>
        <CallAttempts campaignData={campaignData} campaignStatData={campaignStatData} campaignStatTable={campaignStatTable} isLoading={isLoading} />
      </DashboardLayout>
   </>
  )
}

export default Calls
